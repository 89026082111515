import type { MainToWorkerMessage, WorkerToMainMessage } from './types.ts';

onmessage = (pEvent: MessageEvent<MainToWorkerMessage>) => {

    postToMain({ type: 'ready' });
};

function postToMain(pMessage: WorkerToMainMessage) {
    postMessage(pMessage);
}
